import { Box, Typography } from "@material-ui/core";
import style from "./DashboardComponent.module.scss";

export function DashboardCardRowComponent(props: {
	text: string,
	value: string,
	secondValue?: string,
	isLastRow?: boolean
}) {

	return <Box
		className={style.dashboardRow}
		component="span"
		display="flex"
		borderBottom={props.isLastRow ? 0 : 1}
		style={{
			justifyContent: "space-between",
			borderColor: "#e6e6e6"
		}}
	>
		<Typography
			variant="h3"
		>
			{props.text}
		</Typography>
		<Box>
			<Typography
				variant="h3"
				align="right"
			>
				{props.value}
			</Typography>
			{props.secondValue == null ? "" :
				<Typography
					className={style.dashboardSecondValue}
					variant="h4"
					align="right"
				>
					{props.secondValue}
				</Typography>
			}

		</Box>
	</Box>

}