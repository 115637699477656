import { adminClient } from "@api/admin/AdminClient";
import { DasboardCardComponent } from "@components/admin/dashboards/DashboardCardComponent";
import { DashboardCardRowComponent } from "@components/admin/dashboards/DashboardCardRowComponent";
import { DashboardComponent } from "@components/admin/dashboards/DashboardComponent";
import StackBarSectionComponent from "@components/admin/stats/StackBarSectionComponent";
import { GraphPoint } from "@components/admin/stats/StatsHelper";
import { Box } from "@material-ui/core";
import { AdminUserSessionStatsAggregationResponses, AdminUsersSessionsAggregationResponse, UserStatisticsAggregationTimeRangeType } from "@reshopper/admin-client";
import { Countries } from "@reshopper/web-client";
import { formatNumber } from "@utils/formatting";
import { getDanishWeek, toDanishDateFormat } from "@utils/miscellaneous";
import { toPascalCase } from "@utils/strings";
import { getDate, getDay, getMonth, getYear, startOfDay, subDays, subMonths, subWeeks, subYears } from "date-fns";
import { isEqual } from "lodash";
import { useState } from "react";

export default function UserSessionsDashboardComponent() {
	const [graphPoints, setGraphPoints] = useState<GraphPoint[]>(new Array<GraphPoint>());
	const [lastRefreshUtc, setLastRefreshUtc] = useState<Date>(new Date());
	const [userSessionsAggregations, setUserSessionsAggregations] = useState<AdminUserSessionStatsAggregationResponses>();

	
	async function fetchUserSessionsAggregationsAndMapToGraphPoints(country: Countries) {
		let sessionAggregations = await adminClient().adminStatsSessionStatsGet(country);
		setUserSessionsAggregations(sessionAggregations);
		let graphPoints = generateGraphPoints(sessionAggregations);
		setGraphPoints(graphPoints);
	}

	function generateGraphPoints(sessionAggregations: AdminUserSessionStatsAggregationResponses) {
		return sessionAggregations.dailyActiveTotalUsers.map(stat => {
			return {
				x: stat.date,
				y1: stat.count
			} as GraphPoint
		});
	}

	function isTypeMonthlyUnique(type: UserStatisticsAggregationTimeRangeType) {
		return type === "monthly";
	}

	function isTypeWeeklyUnique(type: UserStatisticsAggregationTimeRangeType) {
		return type === "weekly";
	}

	function isTypeDaily(type: UserStatisticsAggregationTimeRangeType) {
		return type === "daily";
	}

	function getStatTitleFromType(type: UserStatisticsAggregationTimeRangeType) {
		if (type === "monthly") {
			return "MAU";
		}
		if (type === "weekly") {
			return "WAU";
		}
		if (type === "daily") {
			return "DAU";
		}
		return "-";
	};

	function getSessionTitleByStat(stat: AdminUsersSessionsAggregationResponse) {
		let type = stat.timeRangeType;
		let date = stat.date;
		let now = new Date();
		if (isTypeMonthlyUnique(type)) {
			if (getMonth(date) === getMonth(now)) {
				return "This month"
			}
			return toPascalCase(toDanishDateFormat(date, "MMM")) + " " + toDanishDateFormat(date, "y");
		}
		if (isTypeWeeklyUnique(type)) {
			if (getDanishWeek(date) === getDanishWeek(now)) {
				return "This week"
			}
			return "Week " + toDanishDateFormat(date, "w");
		}
		if (isTypeDaily(type)) {
			if (getDate(date) === getDate(now)) {
				return "Today"
			}
			return toDanishDateFormat(date, "d") + ". " + toDanishDateFormat(date, "LLL");
		}
		return "-";
	}

	function getLastYearCountByStat(stat: AdminUsersSessionsAggregationResponse | undefined) {
		if (!stat) {
			return;
		}
		let type = stat.timeRangeType;
		let date = stat.date;
		let lastYear = getYear(subYears(date, 1));
		if (isTypeMonthlyUnique(type)) {
			let sameStatLastYear = userSessionsAggregations?.monthlyActiveUniqueUsers.find(x =>
				getYear(x.date) === lastYear &&
				getMonth(x.date) === getMonth(date));
			return sameStatLastYear?.count;
		}
		if (isTypeWeeklyUnique(type)) {
			let sameStatLastYear = userSessionsAggregations?.weeklyActiveUniqueUsers.find(x =>
				getYear(x.date) === lastYear &&
				getDanishWeek(x.date) === getDanishWeek(date));
			return sameStatLastYear?.count;
		}
		if (isTypeDaily(type)) {
			let sameStatLastYear = userSessionsAggregations?.dailyActiveUniqueUsers.find(x =>
				getYear(x.date) === lastYear &&
				getDanishWeek(x.date) === getDanishWeek(date) &&
				getDay(x.date) === getDay(date));
			return sameStatLastYear?.count;
		}
	}

	function renderActiveUsersStatBox(stats: AdminUsersSessionsAggregationResponse[]) {
		if (stats.length === 0) {
			return;
		}

		let type = stats[0].timeRangeType;
		let now = new Date();

		let firstStat = {} as AdminUsersSessionsAggregationResponse | undefined;
		let secondStat = {} as AdminUsersSessionsAggregationResponse | undefined;
		let thirdStat = {} as AdminUsersSessionsAggregationResponse | undefined;

		if (isTypeMonthlyUnique(type)) {
			firstStat = stats.find(x =>
				getMonth(x.date) === getMonth(now) &&
				getYear(x.date) === getYear(now));

			let lastMonth = subMonths(now, 1);
			secondStat = stats.find(x =>
				getMonth(x.date) === getMonth(lastMonth) &&
				getYear(x.date) === getYear(lastMonth));


			let twoMonthsAgo = subMonths(now, 2);
			thirdStat = stats.find(x =>
				getMonth(x.date) === getMonth(twoMonthsAgo) &&
				getYear(x.date) === getYear(twoMonthsAgo));
		}

		if (isTypeWeeklyUnique(type)) {
			firstStat = stats.find(x =>
				getDanishWeek(x.date) === getDanishWeek(now) &&
				getYear(x.date) === getYear(now));

			let lastWeek = subWeeks(now, 1);
			secondStat = stats.find(x =>
				getDanishWeek(x.date) === getDanishWeek(lastWeek) &&
				getYear(x.date) === getYear(lastWeek));

			let twoWeeksAgo = subWeeks(now, 2);
			thirdStat = stats.find(x =>
				getDanishWeek(x.date) === getDanishWeek(twoWeeksAgo) &&
				getYear(x.date) === getYear(twoWeeksAgo));
		}

		if (isTypeDaily(type)) {
			let startOfDayOfNow = startOfDay(now);
			firstStat = stats.find(x => isEqual(startOfDay(x.date), startOfDayOfNow));

			secondStat = stats.find(x => isEqual(startOfDay(x.date), subDays(startOfDayOfNow, 1)));

			thirdStat = stats.find(x => isEqual(startOfDay(x.date), subDays(startOfDayOfNow, 2)));
		}

		return <>
			<DasboardCardComponent cardTitle={getStatTitleFromType(type)} sizeNumberDividedWithHundred={3} >
				{renderStat(firstStat, 0)}
				{renderStat(secondStat, 1)}
				{renderStat(thirdStat, 2)}
			</DasboardCardComponent>
		</>
	}

	function renderStat(stat: AdminUsersSessionsAggregationResponse | undefined, index: number) {
		let lastYearCount = getLastYearCountByStat(stat);
		let isLast = index === 2;
		let isFirst = index === 0;
		return <>
			<DashboardCardRowComponent
				text={!stat ? "-" : getSessionTitleByStat(stat)}
				value={formatNumber(stat?.count ?? 0)}
				secondValue={formatNumber(lastYearCount)} isLastRow={isLast} />
		</>
	}

	return <DashboardComponent
		onRefresh={async country => {
			await fetchUserSessionsAggregationsAndMapToGraphPoints(country)
			setLastRefreshUtc(new Date());
		}}
	>
		<Box 
			display="flex"
			flexDirection="row"
		>
			{!!userSessionsAggregations && renderActiveUsersStatBox(userSessionsAggregations?.dailyActiveUniqueUsers)}
			{!!userSessionsAggregations && renderActiveUsersStatBox(userSessionsAggregations?.weeklyActiveUniqueUsers)}
			{!!userSessionsAggregations && renderActiveUsersStatBox(userSessionsAggregations?.monthlyActiveUniqueUsers)}
		</Box>
		<Box 
			display="flex"
			flexDirection="row"
		>
			<DasboardCardComponent cardTitle="SESSIONS">
				{graphPoints.length === 0 ?
					<></> :
					<>
						<StackBarSectionComponent
							stats={graphPoints}
							displayTicks={true}
							labelFontSize={35}
							tickFontSize={22}
							height={520}
							marginLeft={0}
							marginBottom={34}
							barBackgroundColors={["#62AED6"]}
							textColor="#60453F"
							backgroundColor="#fafafa"
						/>
					</>}
			</DasboardCardComponent>
		</Box>
		<div >
			<footer >
				{toDanishDateFormat(lastRefreshUtc)}
			</footer>
		</div>
	</DashboardComponent>
};